import Promise from "promise";
import lunr from "lunr";
import stemmer from 'lunr-languages/lunr.stemmer.support';
import multi from 'lunr-languages/lunr.multi';
import de from 'lunr-languages/lunr.de';
import es from 'lunr-languages/lunr.es';
import fr from 'lunr-languages/lunr.fr';
import nl from 'lunr-languages/lunr.nl';
import ru from 'lunr-languages/lunr.ru';

stemmer(lunr);
de(lunr);
es(lunr);
fr(lunr);
nl(lunr);
ru(lunr);
multi(lunr);
import indexFunctions from "../../../services/space/customSearchIndexFunctions";
var customHyphenFilter = indexFunctions.customHyphenFilter;
var customStopWordFilter = indexFunctions.customStopWordFilter;
let is404 = false;
lunr.tokenizer.separator = Object.freeze(/[\s]+/);
lunr.QueryLexer.termSeparator = lunr.tokenizer.separator;

var parentSearchKey = "";

function getSearchVal(searchVal) {
    var originalSearchVal = searchVal;
    if (searchVal.indexOf('labels:') != -1) {
        searchVal = labelsSearchVal(searchVal);
    } else if (searchVal.length > 0) {
        searchVal = parseSearchVal(searchVal);
        //duplicate because sometimes wildcard results aren't enough..
        if (originalSearchVal != searchVal) {
            searchVal = originalSearchVal + ' ' + searchVal
        }
    }
    return searchVal;
}

function getLabels(field) {
    var searchVal = " ";
    var values = field.getAttribute("value").split(",");
    for (var i = 0; values.length > i; i++) {
        searchVal = searchVal + field.getAttribute("name") + ":" + values[i] + " ";
    }
    return searchVal;
}

function parseSearchVal(val) {
    val = val.replace(/\*/g, '');


    if (!endWithSpecialChar(val) || val.indexOf('+') != 0) {
        val = val + '*';
    }

    //replace spaces with a wildcard for more inclusive search result
    val = val.replace(/\b\s(?!\*|\+)/g, '* ');

    return val;
}

function labelsSearchVal(val) {
    //get label
    return val = val.replace(/((\b|\+|\-)\S+\:)(\S+)|(\S+)/g, function (m, group) {
        if (group) {
            return m;
        } else {
            return '';
        }
    })
}

function endWithSpecialChar(val) {
    return val.lastIndexOf('+') == (val.length - 1)
        || val.lastIndexOf(' ') == (val.length - 1)
        || val.lastIndexOf('*') == (val.length - 1)

}

$(document).on("submit", "form[name='livesearchForm']", function (e) {
    e.preventDefault();
    var searchFilters = ["labels"];
    var fields = $(e.target).find("input[type='hidden']");
    var searchVal = $(e.target).find("input").val();
    var originalSearchVal = searchVal;
    searchVal = searchVal.replace(/\s(\w)/g, ' +$1');
    searchVal = searchVal.replace(/^(\w)/g, '+$1');
    searchVal = searchVal.replace(/(\+(?!:\S+\:)\w+)/g, '$1*');
    fields.each((i, field) => {
        if (searchFilters.includes(field.getAttribute("name")) ? field.getAttribute("value").length > 0 : false) {
            switch (field.getAttribute("name")) {
                case "labels": searchVal = searchVal + getLabels(field); break;
                default: searchVal = searchVal + " " + field.getAttribute("name") + ":" + field.getAttribute("value") + " ";
            }
        }
    })
    showSearchResults(searchVal).then(results => {
        showSearchForm(originalSearchVal);
    })
});

function showSearchFilter(query) {
    var $filterResults = $("#filter-results");

    var results = query.split(" ").filter(result => {
        return result.indexOf(":") > 0 && result.indexOf(":") + 1 < result.length;
    }).map(result => {
        return {
            key: parseKey(result.substr(0, result.indexOf(":"))),
            value: result.substr(result.indexOf(":") + 1, result.length)
        }
    })
    var $resultContainer = $("<div>");
    results.map(result => {
        buildFilterResults(result, $resultContainer);
    })
    $filterResults.html($resultContainer);
}

function parseKey(key) {
    var key = key.trim();
    if (key.indexOf('+') == 0) {
        return key.substr(1, key.length)
    } else {
        return key;
    }
}

function buildFilterResults(result, $resultContainer) {
    switch (result.key) {
        case "labels":
            var $result = $resultContainer.find(`p[data-key="${result.key}"]`);
            if ($result.length > 0) {
                $result.append(getLabel(result.value));
            } else {
                $resultContainer.append(`<p data-key="${result.key}">${result.key}: ${getLabel(result.value)}</p>`);
            }
            break;
        default:
            var $result = $resultContainer.find(`p[data-key="${result.key}"]`);
            if ($result.length > 0) {
                $result.append(" " + result.value);
            } else {
                $resultContainer.append(`<p data-key="${result.key}">${result.key}: ${result.value}</p>`);
            }
    }
}

$(document).on("submit", "form.searchForm", function (e) {
    e.preventDefault();
    showSearchResults($(e.target).find("input").val());
});

var timeout;
function debounce(f, i) {
    if (timeout) {
        clearTimeout(timeout);
    }

    timeout = setTimeout(f, i);
}

$(document).on("input", ".searchInput > input", e => debounce(() => { showSearchResults($(e.target).val()); }, 100));
$(document).on("click", ".searchInput > button.close", closeSearchResults);
$(document).on("click", "div.search > button", () => { showSearchForm(""); $(".searchForm").submit(); });
$(document).on("keydown", handleEscape);
$(document).on("click", ".label,.aui-label,.label-link", e => { e.preventDefault(); showLabel(e.target.innerText) });

function handleEscape(e) {
    if (e.which === 27) {
        closeSearchResults();
    }
}

function closeSearchResults(e) {
    if (e) {
        e.preventDefault();
    }
    if (!is404) {
        $("body").removeClass("search");
        parentSearchKey = "";
    }
}

function getFilteredResults(results, unfilteredResults) {
    var filteredResult = [];
    unfilteredResults.map((unfilteredResult) => {
        results.map((result) => {
            if (result.title === unfilteredResult.title) {
                filteredResult.push(result);
            }
        })
    })
    return filteredResult;
}

function showLabel(label) {
    var searchVal = "+labels:" + label;
    showSearchResults(searchVal).then(results => {
        showSearchForm("");
    })
}

function filterResultsFromParent(results){

    if (
        Array.isArray(results) &&
        parentSearchKey &&
        values &&
        typeof values === "object" &&
        values[parentSearchKey] &&
        values[parentSearchKey].encodedPath &&
        values[parentSearchKey].encodedPath !== "/"
    ) {
        results = results.filter((x) => {
            if(x.encodedPath && typeof x.encodedPath === "string"){
                if (
                    x.encodedPath.indexOf(values[parentSearchKey].encodedPath) === 0 &&
                    x.encodedPath.charAt(values[parentSearchKey].encodedPath.length) === "/"
                ) {
                    return true;
                }
                return false;
            }
            return false;
        });
    }

    return results;
}

function showSearchResults(query) {
    try {
        var parsedQuery = getSearchVal(query);
        $("body").addClass("search");
        $(".searchForm input").focus();
        var $ol = $("form.searchForm > ol");
        if (parsedQuery && parsedQuery.length >= 4) {
            showSearchFilter(query);
            return search(parsedQuery).then(results => {
                if (parsedQuery.indexOf('labels:') != -1) {
                    return search(query).then(unfilteredResults => {
                        var filteredResults = getFilteredResults(results, unfilteredResults);
                        if (
                            parentSearchKey &&
                            typeof values === "object" &&
                            values[parentSearchKey]
                        ) {
                            filteredResults = filterResultsFromParent(filteredResults)
                        }
                        $ol.empty();
                        $ol.append(filteredResults.map(getResultRow));
                        showSearchForm(query);
                        return Promise.resolve(filteredResults);
                    });
                } else {
                    if (
                        parentSearchKey &&
                        typeof values === "object" &&
                        values[parentSearchKey]
                    ) {
                        results = filterResultsFromParent(results)
                    }
                    $ol.empty();
                    $ol.append(results.map(getResultRow));
                    showSearchForm(query);
                    return Promise.resolve(results);
                }

            });
        } else {
            $ol.empty();
        }
    } catch (err) {
        console.err(err);
    }

    return Promise.resolve(parsedQuery);
}



function getResultRow(result) {
    return (
        `<li>
            <div>
                <a href="${result.encodedPath}">${result.title}</a>
                <span>${result.breadCrumbs}</span>
                <span>${getLabelRow(result.labels)}</span>
            </div>
        </li>`
    );
}

function getLabelRow(labels) {
    var result = "";
    if (labels) {
        labels.map(label => {
            result = result + getLabel(label.label);
        })
    }

    return result;
}

function getLabel(label) {
    return `<span class="label aui-label"><a class="label-link" data-label="${label}" href="#">${label}</a></span>`;
}

var values;
var index;
var loadingPromise;

function getIndex() {
    if (loadingPromise) {
        return loadingPromise;
    }

    loadingPromise = new Promise((resolve, reject) => {
        if (index) {
            return resolve(index);
        }

        $.getJSON("/search/index.json", resp => {         
            lunr.multiLanguage('en', 'de', 'es', 'fr', 'nl', 'ru');
            lunr.Pipeline.registerFunction(customHyphenFilter, 'customHyphenFilter');
            lunr.Pipeline.registerFunction(customStopWordFilter, 'customStopWordFilter');
            index = lunr.Index.load(resp.index);
            index.pipeline.add(customStopWordFilter);
            index.pipeline.add(customHyphenFilter);
            values = resp.values;
            return resolve(index);
        });
    });

    return loadingPromise;
}

function showSearchForm(query) {
    var $searchForm = $(".searchForm");
    getIndex().then(() => $searchForm.removeClass("loading"));
    $searchForm.find("input").val(query);
}

function search(query) {
    return getIndex().then(index => index.search(query).map(page => { return values[page.ref] }));
}

$(function () {
    if ($("body").hasClass("search")) {
        is404 = true;
        var pathname = window.location.pathname;
        if (pathname.lastIndexOf("/") === pathname.length - 1) {
            pathname = pathname.substr(0, pathname.length - 1);
        }
        var pageName = pathname.substr(pathname.lastIndexOf("/") + 1);
        var query = decodeURIComponent(decodeURIComponent(pageName)).replace(/\-/g, " ");

        showSearchForm(query);
        $(".searchForm").submit();
    }
});

$("#pagetreesearch form").each((i, element) => {
    $(element).on("submit", (event) => {
        event.preventDefault();
        var searchValue = $(element).find("[type=text]").val();
        var pageKey = $(element).find("[name=ancestorId]").val();//spaceKey
        if(pageKey){
            parentSearchKey = pageKey;
        }
        showSearchResults(searchValue)
    })
})